import React from "react";

function HomePage() {
  const isAndroid = /Android/.test(navigator.userAgent);
  const isIOS = /iPhone|iPad|iPod/.test(navigator.userAgent);
  const isWindows = /Windows/.test(navigator.userAgent);
  if (isAndroid === true || isWindows === true) {
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.twikcsocial.twikc";
  } else {
    window.location.href = "https://apps.apple.com/us/app/twikc/id6447326832";
  }
  return (
    <div
      style={{ height: "100vh", width: "100vw", backgroundColor: "#101010" }}
    ></div>
  );
}

export default HomePage;
